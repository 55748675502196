import React, {Fragment, useRef, useState, useEffect} from 'react';
import styled from 'styled-components';
import NukaCarousel from 'nuka-carousel';
import Image, {ImageWrapper} from '../../../Components/Image';
import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  Close,
} from '@styled-icons/material';
import {Carousel as RespCarousel} from 'react-responsive-carousel';
import {Mask} from '../../../Widgets';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import useDimension from '../../../hooks/use-dimension';

const RATIO = 1 / 1;
const gap = 10;

function renderControl(side) {
  switch (side) {
    case 'right':
      return ({nextSlide}) => (
        <IconContainer onClick={nextSlide}>
          <KeyboardArrowRight color="#4e4e4e" style={{width: 35, height: 35}} />
        </IconContainer>
      );
    case 'left':
      return ({previousSlide}) => (
        <IconContainer onClick={previousSlide}>
          <KeyboardArrowLeft color="#4e4e4e" style={{width: 35, height: 35}} />
        </IconContainer>
      );
    default:
      return () => false;
  }
}

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.4);
  padding: 2px;
`;

function Carousel(props) {
  const [index, setIndex] = useState(0);
  const images = props.images.filter((image) => image.name === 'top');

  return (
    <NukaCarousel
      autoplay={false}
      width={props.size || '100%'}
      initialSlideHeight={300}
      renderBottomCenterControls={renderControl()}
      renderCenterLeftControls={renderControl('left')}
      renderCenterRightControls={renderControl('right')}
      slideIndex={index}
      afterSlide={(index) => setIndex(index)}
      style={{outline: 'none'}}>
      {images?.length > 0 ? (
        images.map((d, i) => (
          <Image
            key={i}
            ratio={RATIO}
            src={d.image}
            alt={'product image - ' + i}
          />
        ))
      ) : (
        <div style={{width: '100%', height: 300, backgroundColor: '#f0f0f0'}} />
      )}
    </NukaCarousel>
  );
}

function LightBox(props) {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(null);
  const {css, size, height} = props;
  const images = props.images.filter((image) => image.name === 'top');

  if (images?.length > 0) {
    return (
      <ThumbnailWrapper css={css} size={size} height={height}>
        <div className="thumbnails-wrapper">
          {images.map((d, i) => {
            return (
              <Image
                alt={'產品縮圖' + i}
                key={i}
                src={d.image}
                style={{
                  width: size,
                  height: size,
                  cursor: 'pointer',
                  flexShrink: 0,
                  marginBottom: gap,
                }}
                background
                onClick={() => {
                  setSelected(i);
                  setOpen(true);
                }}
              />
            );
          })}
        </div>

        {selected !== null ? (
          <LightBoxWrapper show={selected !== null}>
            <Mask
              visible={selected !== null}
              onClick={() => setSelected(null)}
              color="#0009"
              style={{zIndex: 0}}
            />
            <div className="carousel-wrapper">
              <div className="close" onClick={() => setSelected(null)}>
                <Close size={20} color="#fff" />
              </div>

              <RespCarousel
                selectedItem={selected}
                showArrows={true}
                showStatus={false}
                dynamicHeight={true}
                onChange={(idx) => setSelected(idx)}
                onClickItem={() => {}}
                onClickThumb={(idx) => setSelected(idx)}
                renderThumbs={(children) =>
                  children.map((item) => <img src={item.props.src} />)
                }
                width="100%">
                {images.map((image, idx) => (
                  <img src={image.image} width="100%" height="auto" key={idx} />
                ))}
              </RespCarousel>
            </div>
          </LightBoxWrapper>
        ) : null}
      </ThumbnailWrapper>
    );
  }

  return null;
}

const ThumbnailWrapper = styled.div`
  flex: 0 0 ${(props) => props.size}px;

  & > .thumbnails-wrapper {
    display: flex;
    flex-direction: column;
    max-height: ${(props) => props.height}px;
    overflow: auto;
    justify-content: flex-start;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
  }

  & > .thumbnails-wrapper::-webkit-scrollbar {
    display: none;
  }

  & > .thumbnails-wrapper.center {
    justify-content: center;
  }
  ${(props) => props.css}
`;

const LightBoxWrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 15;
  width: 100vw;
  height: 100vh;
  overflow: scroll;
  transition: all 0.2 ease;
  opacity: ${(props) => (props.show ? 1 : 0)};

  & > .carousel-wrapper {
    max-width: 900px;
    margin: 0 auto;
    z-index: 1;

    & .carousel .thumb {
      padding: 0;
      border-radius: 2px;
      border: solid 4px #999;
    }

    & .carousel .thumb.selected,
    .carousel .thumb:hover,
    .carousel .thumb:focus {
      border: solid 4px #fff;
    }

    & > .close {
      padding: 15px;
      position: relative;
      cursor: pointer;
      text-align: right;
    }
  }
`;

export default function ImageSection({images}) {
  const wrapperRef = useRef();
  const {dimension} = useDimension();
  const [bigSize, setBigSize] = useState(0);
  const [smallSize, setSmallSize] = useState(10);

  useEffect(() => {
    // to get unit size
    if (wrapperRef.current) {
      let {width} = wrapperRef.current.getBoundingClientRect();
      let length = 5;

      // width = (4X + 3 * 10) + (10+ X);
      let smallSize = (width - (length - 1) * gap) / length; // = X
      let bigSize = 4 * smallSize + (length - 2) * gap; // 4X + 3 * 10

      setSmallSize(smallSize);
      setBigSize(bigSize);
    }
  }, [wrapperRef, dimension.innerWidth]);

  return (
    <div
      ref={(ref) => (wrapperRef.current = ref)}
      style={{display: 'flex', alignItems: 'stretch'}}>
      <Carousel images={images} size={bigSize} />
      <div style={{flex: `0 0 ${gap}px`}} />
      <LightBox images={images} size={smallSize} height={bigSize} />
    </div>
  );
}
