import React, {useState} from 'react';
import styled from 'styled-components';
import Image from '../../../Components/Image';
import {Tab} from '../../../Widgets';
import ProductFile from '../ProductFile';
import {Center} from '../../../Components/Layout/ContentWrapper';
const config = require('../../../data.json');

const {TabBar, TabItem: _TabItem, TabContent} = Tab;

const TABS = {
  INTRO: 0,
  SPEC: 1,
  NOTICE: 2,
  TEMPLATE: 3,
};

export default function BottomSection(props) {
  const [index, setIndex] = useState(TABS.INTRO);
  const {product} = props;
  if (!product) {
    return null;
  }

  return (
    <div
      style={{
        width: '100vw',
        paddingBottom: 30,
      }}>
      <Center>
        <Wrapper>
          <TabBar>
            {['產品介紹', '產品規格', '完稿須知', '刀模下載'].map(
              (tab, idx) => (
                <TabItem
                  key={idx}
                  selected={idx === index}
                  onClick={() => setIndex(idx)}>
                  {tab}
                </TabItem>
              ),
            )}
            <TabItem key="empty" style={{flex: 1}} />
          </TabBar>

          <TabContent style={{padding: '40px 0px'}}>
            {index === TABS.INTRO && (
              <div className="image-container">
                {product.images
                  .filter((image) => image.name === 'intro')
                  .map((img, idx) => (
                    <Image
                      alt={'介紹' + idx}
                      key={idx}
                      style={{width: '100%'}}
                      src={img.image}
                      linkto={img.link}
                    />
                  ))}
              </div>
            )}

            {index === TABS.SPEC && (
              <div className="image-container">
                {product.images
                  .filter((image) => image.name === 'spec')
                  .map((img, idx) => (
                    <Image
                      alt={'規格' + idx}
                      key={idx}
                      style={{width: '100%'}}
                      src={img.image}
                      linkto={img.link}
                    />
                  ))}
              </div>
            )}

            {index === TABS.NOTICE && (
              <div className="image-container">
                {product.images
                  .filter((image) => image.name === 'notice')
                  .map((img, idx) => (
                    <Image
                      alt={'注意事項' + idx}
                      key={idx}
                      style={{width: '100%'}}
                      src={img.image}
                      linkto={img.link}
                    />
                  ))}
              </div>
            )}

            {index === TABS.TEMPLATE && product.files && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: config.productFileDirection,
                  flexWrap: 'wrap',
                }}>
                {product.files.map((f, idx) => (
                  <ProductFile file={f} key={idx} index={idx} />
                ))}
              </div>
            )}
          </TabContent>
        </Wrapper>
      </Center>
    </div>
  );
}

const Wrapper = styled.div`
  background-color: white;

  & .image-container {
    margin: 0 auto;
    max-width: 860px;
  }
`;

const TabItem = styled(_TabItem)`
  flex: 0 0 auto;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border: solid 0.5px ${(props) => (props.selected ? '#5a5a5a' : 'white')};
  border-bottom: solid 0.5px
    ${(props) => (props.selected ? 'white' : '#5a5a5a')};
  padding-right: 20px;
  padding-left: 20px;

  background-color: white;
  color: ${(props) => (props.selected ? '#2f2f2f' : '#989898')};
  cursor: pointer;

  transition: border-color 0.3s ease;
`;
