import React from 'react';
import styled from 'styled-components';
import {Download} from '@styled-icons/entypo';
import {downloadFile} from '../../../Utils';
const config = require('../../../data.json');

export default function ProductFile({file, index}) {
  return file ? (
    <Wrapper>
      <div style={{color: config.colors.text, fontSize: 16}}>{`${index + 1}. ${
        file.name
      }`}</div>
      <div
        style={{
          color: config.colors.second,
          marginBottom: 20,
        }}>
        {file.file.split('/').slice(-1)}
      </div>
      <button
        style={{
          backgroundColor: config.colors.second,
          color: 'white',
          border: 0,
          borderRadius: 8,
          fontSize: 18,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '8px 10px',
          cursor: 'pointer',
        }}
        onClick={() => downloadFile(file.file)}>
        <Download size={20} color="white" style={{marginRight: 8}} />
        DOWNLOAD
      </button>
      <div></div>
    </Wrapper>
  ) : null;
}

const Wrapper = styled.div`
  margin-right: 15px;
  margin-bottom: 15px;
  flex: 0 0 22%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f7f7f7;
  padding: 20px;

  @media screen and (max-width: ${config.breakpoints.lg}px) {
    flex: 0 0 48%;
  }
  @media screen and (max-width: ${config.breakpoints.sm}px) {
    flex: 0 0 100%;
  }
`;
